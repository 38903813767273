.gallery-root {
  width: 100%;

  display: flex;

  justify-content: center;
}

.gallery-column {
  flex: calc(50% - 4 * 4px);
  padding: 0 4px;
}
