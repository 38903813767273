body {
  margin: 0;

  background-color: white;
  color: rgb(35, 35, 35);

  font-family: sans-serif;
}

.header {
  width: 100%;
  height: 115px;
}

.logo-size {
  width: 100%;
  height: 75px;
}

.logo-align {
  display: flex;

  justify-content: center;
  align-content: center;
}

.logo-image {
  height: 100%;
}

.header-links {
  width: 100%;
  height: 40px;

  display: flex;

  justify-content: center;
  align-content: center;
}

.link-icon-size {
  margin-top: 4px;
  margin-left: 4px;
  margin-right: 4px;

  width: 25px;
  height: 20px;
}

.link-icon {
  color: rgb(35, 35, 35);
}

.link-icon:visited {
  color: rgb(35, 35, 35);
}

.link-icon:hover {
  color: rgb(35, 35, 35);
}

.link-icon:active {
  color: rgb(100, 100, 100);
}

@media screen and (max-width: 650px) {
  .header {
    width: 100%;
    height: 105;
  }

  .logo-size {
    width: 100%;
    height: 65px;
  }

  .link-icon-size {
    margin-top: 4px;
    margin-left: 4px;
    margin-right: 4px;
  
    width: 25px;
    height: 20px;
  }
}
