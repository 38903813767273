.gallery-item-yt {
  position: relative;

  margin-top: 8px;
  margin-left: 4px;
  margin-right: 4px;

  width: auto;

  vertical-align: middle;
}

.gallery-yt {
  width: 100%;
  height: 100%;

  border-style: solid;
  border-width: 1px;
  border-color: transparent;
}

.gallery-yt:hover {
  border-color: lightblue;
}

.gallery-yt-icon {
  position: absolute;
  width: 30%;
  height: 30%;
  top: 35%;
  left: 35%;

  color: white;
}

.gallery-yt-icon > img {
  color: white;
}
