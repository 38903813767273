.gallery-modal {
  display: flex;
  justify-content: center;
  align-items: center;
}

.gallery-modal.visible {
  position: fixed;

  z-index: 50;

  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background-color: rgba(25, 25, 25, 0.45);
}

.gallery-modal.hidden {
  position: fixed;

  top: 0;
  left: 0;
  width: 0px;
  height: 0px;

  overflow: hidden;
}

.gallery-modal-window {
  display: flex;

  border-radius: 8px;

  background-color: white;

  margin: 24px;
}

.gallery-modal-content {
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  width: 100%;
}

.gallery-modal-video {
  border-radius: 5px;
  margin: 8px;
}

.gallery-modal-image {
  border-radius: 5px;

  max-height: 1800px;
  max-width: 1800px;
  margin: 8px;
}

@media screen and (max-width: 2560px) {
  .gallery-modal-image {
    border-radius: 5px;
  
    max-height: 900px;
    max-width: 1280px;
    margin: 8px;
  }
}

@media screen and (max-width: 1920px) {
  .gallery-modal-image {
    border-radius: 5px;
  
    max-height: 775px;
    max-width: 1050px;
    margin: 8px;
  }
}

@media screen and (max-width: 1280px) {
  .gallery-modal-image {
    border-radius: 5px;
  
    max-height: 650px;
    max-width: 900px;
    margin: 8px;
  }
}

@media screen and (max-width: 650px) {
  .gallery-modal-image {
    border-radius: 5px;
  
    max-height: calc(100% - 16px);
    max-width: calc(100% - 16px);
    margin: 8px;
  }
}

.gallery-modal-loading {
  display: flex;

  padding: 8px;

  text-align: center;
  justify-content: center;
  align-items: center;
}