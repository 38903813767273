.gallery-item {
  margin-top: 8px;
  margin-left: 4px;
  margin-right: 4px;

  width: auto;

  vertical-align: middle;
}

.gallery-image {
  width: 100%;
  height: 100%;

  border-style: solid;
  border-width: 1px;
  border-color: transparent;
}

.gallery-image:hover {
  border-color: lightblue;
}
