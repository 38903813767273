body {
  color: #232323;
  background-color: #fff;
  margin: 0;
  font-family: sans-serif;
}

.header {
  width: 100%;
  height: 115px;
}

.logo-size {
  width: 100%;
  height: 75px;
}

.logo-align {
  place-content: center;
  display: flex;
}

.logo-image {
  height: 100%;
}

.header-links {
  place-content: center;
  width: 100%;
  height: 40px;
  display: flex;
}

.link-icon-size {
  width: 25px;
  height: 20px;
  margin-top: 4px;
  margin-left: 4px;
  margin-right: 4px;
}

.link-icon, .link-icon:visited, .link-icon:hover {
  color: #232323;
}

.link-icon:active {
  color: #646464;
}

@media screen and (width <= 650px) {
  .header {
    width: 100%;
    height: 105px;
  }

  .logo-size {
    width: 100%;
    height: 65px;
  }

  .link-icon-size {
    width: 25px;
    height: 20px;
    margin-top: 4px;
    margin-left: 4px;
    margin-right: 4px;
  }
}

.gallery-root {
  justify-content: center;
  width: 100%;
  display: flex;
}

.gallery-column {
  flex: calc(50% - 16px);
  padding: 0 4px;
}

.gallery-item {
  vertical-align: middle;
  width: auto;
  margin-top: 8px;
  margin-left: 4px;
  margin-right: 4px;
}

.gallery-image {
  border: 1px solid #0000;
  width: 100%;
  height: 100%;
}

.gallery-image:hover {
  border-color: #add8e6;
}

.gallery-item-animate-pop {
  animation-name: animate-pop;
  animation-duration: .75s;
  animation-timing-function: cubic-bezier(.26, .53, .74, 1.1);
}

@keyframes animate-pop {
  0% {
    opacity: 0;
    transform: scale(.95);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.gallery-modal {
  justify-content: center;
  align-items: center;
  display: flex;
}

.gallery-modal.visible {
  z-index: 50;
  background-color: #19191973;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

.gallery-modal.hidden {
  width: 0;
  height: 0;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}

.gallery-modal-window {
  background-color: #fff;
  border-radius: 8px;
  margin: 24px;
  display: flex;
}

.gallery-modal-content {
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
  position: relative;
}

.gallery-modal-video {
  border-radius: 5px;
  margin: 8px;
}

.gallery-modal-image {
  border-radius: 5px;
  max-width: 1800px;
  max-height: 1800px;
  margin: 8px;
}

@media screen and (width <= 2560px) {
  .gallery-modal-image {
    border-radius: 5px;
    max-width: 1280px;
    max-height: 900px;
    margin: 8px;
  }
}

@media screen and (width <= 1920px) {
  .gallery-modal-image {
    border-radius: 5px;
    max-width: 1050px;
    max-height: 775px;
    margin: 8px;
  }
}

@media screen and (width <= 1280px) {
  .gallery-modal-image {
    border-radius: 5px;
    max-width: 900px;
    max-height: 650px;
    margin: 8px;
  }
}

@media screen and (width <= 650px) {
  .gallery-modal-image {
    border-radius: 5px;
    max-width: calc(100% - 16px);
    max-height: calc(100% - 16px);
    margin: 8px;
  }
}

.gallery-modal-loading {
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 8px;
  display: flex;
}

.gallery-item-yt {
  vertical-align: middle;
  width: auto;
  margin-top: 8px;
  margin-left: 4px;
  margin-right: 4px;
  position: relative;
}

.gallery-yt {
  border: 1px solid #0000;
  width: 100%;
  height: 100%;
}

.gallery-yt:hover {
  border-color: #add8e6;
}

.gallery-yt-icon {
  color: #fff;
  width: 30%;
  height: 30%;
  position: absolute;
  top: 35%;
  left: 35%;
}

.gallery-yt-icon > img {
  color: #fff;
}

/*# sourceMappingURL=index.4f937e05.css.map */
