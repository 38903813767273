.gallery-item-animate-pop {
  animation-duration: 0.75s;
  animation-name: animate-pop;
  animation-timing-function: cubic-bezier(.26, .53, .74, 1.1);
}

@keyframes animate-pop {
  0% {
    opacity: 0;
    transform: scale(0.95, 0.95);
  }

  100% {
    opacity: 1;
    transform: scale(1, 1);
  }
}